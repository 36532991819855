import { graphql } from 'gatsby';
import * as React from 'react';
import BookshelfList from '../components/bookshelfList';
import Layout from '../components/layout';
import Seo from '../components/seo';

const BookshelfPath = ({ data, location }) => {
  const siteTitle = `Bookshelf`;

  const b2024 = [
    {
      title: 'The Road',
      author: 'Cormac McCarthy',
      slug: 'https://www.goodreads.com/book/show/6288.The_Road',
    },
    {
      title: 'The Alchemist',
      author: 'Paulo Coelho',
      slug: 'https://www.goodreads.com/book/show/18144590-the-alchemist',
    },
    {
      title: 'No Rules Rules',
      author: 'Reed Hastings, Erin Meyer',
      slug: 'https://goodreads.com/book/show/49099937-no-rules-rules',
    },
    {
      title: 'Black Edge',
      author: 'Sheelah Kolhatkar',
      slug: 'https://www.goodreads.com/en/book/show/32284263-black-edge',
    },
    {
      title: 'What it Takes',
      author: 'Stephen Schwarzman',
      slug: 'https://www.goodreads.com/book/show/45733318-what-it-takes',
    },
    {
      title: 'Thinking in Bets',
      author: 'Annie Duke',
      slug: 'https://www.goodreads.com/book/show/35957157-thinking-in-bets',
    },
    {
      title: 'The Hard Thing About Hard Things',
      author: 'Ben Horowitz',
      slug: 'https://www.goodreads.com/book/show/18176747-the-hard-thing-about-hard-things',
    },
    {
      title: 'Amp It Up',
      author: 'Frank Slootman',
      slug: 'https://www.goodreads.com/book/show/59383944-amp-it-up',
    },
    {
      title: 'The Score Takes Care of Itself',
      author: 'Bill Walsh',
      slug: 'https://www.goodreads.com/book/show/6342995-the-score-takes-care-of-itself',
    },
    {
      title: 'Into Thin Air',
      author: 'Jon Krakauer',
      slug: 'https://www.goodreads.com/book/show/1898.Into_Thin_Air',
    },
    {
      title: 'Based on a True Story',
      author: 'Norm Macdonald',
      slug: 'https://www.goodreads.com/book/show/28686959-based-on-a-true-story',
    },
    {
      title: 'Elon Musk',
      author: 'Walter Isaacson',
      slug: 'https://www.goodreads.com/book/show/122765395-elon-musk',
    },
    {
      title: 'Breakfast of Champions',
      author: 'Kurt Vonnegut',
      slug: 'https://www.goodreads.com/book/show/4980.Breakfast_of_Champions',
    },
    {
      title: "Can't Hurt Me",
      author: 'David Goggins',
      slug: 'https://www.goodreads.com/book/show/41721428-can-t-hurt-me',
    },
    {
      title: 'Dark Matter',
      author: 'Blake Crouch',
      slug: 'https://www.goodreads.com/book/show/27833670-dark-matter',
    },
    {
      title: 'The Three-Body Problem',
      author: 'Cixin Liu',
      slug: 'https://www.goodreads.com/book/show/20518872-the-three-body-problem',
    },
    {
      title: "Liar's Poker",
      author: 'Michael Lewis',
      slug: 'https://www.goodreads.com/en/book/show/7865083',
    },
    {
      title: 'Secrets of Sand Hill Road',
      author: 'Scott Kupor',
      slug: 'https://a16z.com/books/secrets-of-sand-hill-road/',
    },
    {
      title: 'Steve Jobs',
      author: 'Walter Isaacson',
      slug: 'https://www.goodreads.com/book/show/11084145-steve-jobs',
    },
  ];

  const others = [
    {
      title: 'Shoe Dog',
      author: 'Phil Knight',
      slug: 'https://www.goodreads.com/book/show/27220736-shoe-dog',
    },
    {
      title: 'The days are long but the decades are short',
      author: 'Sam Altman',
      slug: 'https://blog.samaltman.com/the-days-are-long-but-the-decades-are-short',
      year: '2015',
    },
    {
      title: 'The only thing that matters',
      author: 'Marc Andreessen',
      slug: 'https://pmarchive.com/guide_to_startups_part4.html',
      year: '2007',
    },
    {
      title: "Do Things that Don't Scale",
      author: 'Paul Graham',
      slug: 'http://www.paulgraham.com/ds.html',
      year: '2013',
    },
    {
      title: 'This is Water',
      author: 'David Foster Wallace',
      slug: 'https://web.ics.purdue.edu/~drkelly/DFWKenyonAddress2005.pdf',
      year: '2005',
    },
  ];

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Bookshelf"
        description="A curated list of books and essays that have inspired deep thinking and insight, from classics to modern reads."
      />
      <h1 className="main-heading">Bookshelf</h1>
      <div className="subheader">
        Books and essays that have made me think. I'm a serial book starter (I'm
        working on it), so this list only includes cover-to-cover reads.
      </div>
      <h3>2024</h3>
      <BookshelfList books={b2024} />
      <h3>Other favorites</h3>
      <BookshelfList books={others} />
    </Layout>
  );
};

export default BookshelfPath;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
